<template>
  <div>
    <topHeader :haveBanner="false" />

    <section>
      <div class="authentication">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-3 col-md-2 col-sm-2"></div>
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8">
              <p class="authentication__caption text-center">
                We’ve been expecting you!
              </p>
              <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                  <p class="authentication__caption1 text-center">
                Join our community of professionals and businesses from all over Africa.
              </p>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-3 col-md-2 col-sm-2"></div>
          </div>
          <div class="__spacet2p"></div>
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-2"></div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-8">
              <div class="__formwidth">
                <form @submit.prevent="handleRegistration">
                  <div class="form-group">
                    <label for="" class="form__authformlabel">Full Name</label>
                    <div class="__deffinput" :class="{ 'has-error': v$.firstName.$errors.length || v$.lastName.$errors.length }">
                      <input
                        type="text"
                        class="form-control __deffinput__authformedit"
                        placeholder="First Name"
                        v-model.trim="firstName"
                      />
                      <input
                        type="text"
                        class="form-control __deffinput__authformedit"
                        placeholder="Last Name"
                        v-model.trim="lastName"
                      />
                    </div>
                    <div style="display: flex; justifyContent: space-between">
                      <div class="input-errors" v-for="error of v$.firstName.$errors" :key="error.$uid">
                        <div class="error-msg invalid-feedback" v-show="v$.firstName.$errors.length">{{ error.$message }}</div>
                      </div>
                      <div class="input-errors" v-for="error of v$.lastName.$errors" :key="error.$uid">
                        <div class="error-msg invalid-feedback">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="" class="form__authformlabel">Company’s name</label>
                    <input
                      type="text"
                      class="form-control form__authformedit"
                      placeholder="User Name"
                      v-model.trim="slug"
                      :class="{ 'has-error': v$.slug.$errors.length }"
                    />
                    <div class="input-errors" v-for="error of v$.slug.$errors" :key="error.$uid">
                      <div class="error-msg invalid-feedback" v-show="v$.firstName.$errors.length">{{ error.$message }}</div>
                    </div>
                  </div>

                   <div class="form-group">
                    <label for="" class="form__authformlabel"
                      >Company’s email address</label
                    >
                    <input
                      type="text"
                      class="form-control form__authformedit"
                      placeholder="Hireme@example.gmail.com"
                      v-model.trim="email"
                      :class="{ 'has-error': v$.email.$errors.length }"
                    />
                    <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                      <div class="error-msg invalid-feedback" v-show="v$.email.$errors.length">{{ error.$message }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="" class="form__authformlabel">Password</label>
                    <input
                      :type="showPassword ? 'text':'password'"
                      class="form-control form__authformedit"
                      placeholder="****************"
                      v-model="password"
                      :class="{ 'has-error': v$.password.$errors.length }"
                    />
                    <span class="__showpassword" style="color: gray; fontSize: 14px" @click.prevent="showPassword = !showPassword">
                      <i class="fa fa-eye-slash" v-if="!showPassword" aria-hidden="true"></i>
                      <i class="fa fa-eye" v-if="showPassword" aria-hidden="true"></i>
                    </span>
                    <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                      <div class="error-msg invalid-feedback">{{ error.$message }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="" class="form__authformlabel"
                      >Confirm Password</label
                    >
                    <input
                      :type="showConfirmPassword ? 'text':'password'"
                      class="form-control form__authformedit"
                      placeholder="****************"
                      name="password"
                      v-model="password_confirmation"
                      :class="{ 'has-error': v$.password_confirmation.$errors.length }"
                    />
                    <span class="__showpassword" style="color: gray; fontSize: 14px" @click.prevent="showConfirmPassword = !showConfirmPassword">
                      <i class="fa fa-eye-slash" v-if="!showConfirmPassword" aria-hidden="true"></i>
                      <i class="fa fa-eye" v-if="showConfirmPassword" aria-hidden="true"></i>
                    </span>
                    <div class="input-errors" v-for="error of v$.password_confirmation.$errors" :key="error.$uid">
                      <div class="error-msg invalid-feedback">{{ error.$message }}</div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-block form__authformbutton"
                  >
                    Register now <Loader :shouldShow="shouldShowLoader" />
                  </button>
                </form>

                <p class="authentication__log text-center">
                  Already have an account?
                  <router-link to="/login" class="__loglink">Login here</router-link>
                </p>
                <p class="authentication__log text-center">
                  By registering, you agree to the
                  <a href="https://findworka.com/#/term" class="__loglink"
                    >Terms of use</a
                  >,
                  <a
                    href="https://findworka.com/#/policy"
                    to="/login"
                    class="__loglink"
                    >Privacy Policy</a
                  >, and Community Standards of Bookmehq.com
                </p>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-2"></div>
          </div>
        </div>
        <div class="__spacet7p"></div>
      </div>
    </section>
    <topFooter />
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions } from "vuex";
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, helpers, sameAs } from '@vuelidate/validators';

import topHeader from "../components/topHeader.vue";
import Loader from "../components/loader";
import topFooter from "../components/topFooter";

import { errorHandler } from "../../utils/helpers";

export default {
  name: "Register",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      slug: "",
      password_confirmation: "",
      shouldShowLoader: false,
      showPassword: false,
      showConfirmPassword: false
    };
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      firstName: {
        required: helpers.withMessage('Firstname is required', required),
      },
      lastName: {
        required: helpers.withMessage('Lastname is required', required),
      },
      slug: {
        required: helpers.withMessage('Company name is required', required),
      },
      email: {
        required: helpers.withMessage('Email is required', required),
        email: helpers.withMessage('Please provide a valid email address', email),
      },
      password: {
        required: helpers.withMessage('Password is required', required),
        minLength: helpers.withMessage('Password must have a minimum length of 6 digits', minLength(6)),
      },
      password_confirmation: {
        required: helpers.withMessage('Confirm password is required', required),
        minLength: helpers.withMessage('Password must have a minimum length of 6 digits', minLength(6)),
        sameAsPassword: helpers.withMessage('Confirm password must be the same as Password', sameAs(this.password))
      }
    }
  },
  components: {
    topHeader,
    Loader,
    topFooter
  },
  methods: {
    ...mapActions(["registerUser", "verifyUser"]),
    async handleRegistration() {
      try {
        this.v$.$touch();
        this.shouldShowLoader = true;
        if (this.v$.$error) return;

        const fullName = `${this.firstName} ${this.lastName}`;
        const callBackURL = `${location.origin}/register`;

        const result = await this.registerUser({
          password_confirmation: this.password_confirmation,
          password: this.password,
          email: this.email,
          company_name: this.slug,
          fullName,
          callBackURL,
          type: "company"
        });

        if (result.errors && result.errors.length > 0) {
          this.shouldShowLoader = false;
          return this.$toast.error(`${result.errors[0]}`);
        }

        this.shouldShowLoader = false;
        this.$toast.success(
          `${result.message} - A verification link has been sent to your email`
        );
        this.input = {};

        this.$router.push("/login");
      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
        this.shouldShowLoader = false;
      }
    },
  },
  async mounted() {
    $("#showPassword").click(function () {
      var foo = $(this).prev().attr("type");
      if (foo == "password") {
        $(this).prev().attr("type", "text");
      } else {
        $(this).prev().attr("type", "password");
      }
    });

    $("#showPassword1").click(function () {
      var foo = $(this).prev().attr("type");
      if (foo == "password") {
        $(this).prev().attr("type", "text");
      } else {
        $(this).prev().attr("type", "password");
      }
    });
  },
  async created() {
    if (this.$route.query.key) {
      const result = await this.verifyUser(this.$route.query.key);

      if (result.errors && result.errors.length > 0) {
        return this.$toast.error(`${result.errors[0]}`);
      }

      this.$toast.success(`${result.message}`);

      this.$router.push("/login");
    }
  },
};
</script>

<style lang="scss">
.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
